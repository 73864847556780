<template>
  <div>
    <section>
      <LoaderIcon v-if="showLoader" />
      <b-form id="language-form" name="language-form" v-else @submit.prevent="onSubmit" autocomplete="off" novalidate>
        <b-form-row>
          <b-col cols="12" :sm="7" :md="5" :lg="4">
            <b-form-group>
              <h4 class="page-sub-header">Language</h4>
              <div class="floating-select-field">
                <b-form-select
                  id="language-name"
                  class="form-control"
                  v-model="languageForm.languageName"
                  @input="languageForm.languageName = $event"
                  @change="onChangeLanguage"
                  :class="{
                    'is-value-exist': languageForm.languageName != null,
                    'is-invalid': !languageForm.languageName && formSubmitted,
                  }"
                >
                  <b-form-select-option :key="i" :value="language.countryName" v-for="(language, i) in languagesList">{{
                    language.countryName
                  }}</b-form-select-option>
                </b-form-select>
                <label for="language-name">Language</label>
                <b-form-invalid-feedback class="d-block" v-if="!languageForm.languageName && formSubmitted">Language required.</b-form-invalid-feedback>
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" :sm="7" :md="5" :lg="4">
            <b-form-group class="mb-1">
              <h4 class="page-sub-header">
                ISO code: <span id="iso-code-value">{{ languageForm.cultureCode }}</span>
              </h4>
              <b-form-invalid-feedback class="d-block" v-if="!languageForm.cultureCode && formSubmitted">ISO code required.</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" :sm="7" :md="5" :lg="4">
            <b-form-group>
              <b-form-checkbox id="is-default-language" name="is-default-language" v-model="languageForm.isDefault"> Set as default language </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row v-if="showWarningDefaultMsg && languageForm.languageName">
          <b-col class="d-flex mb-2">
            <div>
              <InfoCircelYellow class="mr-1 mt-n2" />
            </div>
            <div class="font-14">
              {{ showWarningDefaultMsg }}
            </div>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" :sm="7" :md="5" :lg="4">
            <b-form-group>
              <h4 class="page-sub-header">Fall back language</h4>
              <div class="floating-select-field">
                <b-form-select
                  id="fall-back-language"
                  class="form-control"
                  :disabled="!languageForm.languageName"
                  v-model="languageForm.fallBackLanguageId"
                  @input="languageForm.fallBackLanguageId = $event"
                  :class="{
                    'is-value-exist': languageForm.fallBackLanguageId != null,
                  }"
                >
                  <b-form-select-option :value="''">None</b-form-select-option>
                  <b-form-select-option :key="i" :value="type.id" v-for="(type, i) in filterFallbackLanguages">{{ type.languageName }}</b-form-select-option>
                </b-form-select>
                <label for="fall-back-language">Fall back language</label>
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row class="my-4">
          <b-col>
            <b-button type="submit" id="save-language-btn" v-activeBlur variant="primary" :disabled="addEditLanguageLoading"
              >Save <b-spinner v-if="addEditLanguageLoading" label="Spinning" small class="ml-2"></b-spinner
            ></b-button>
            <b-button type="button" id="cancel-language-btn" v-activeBlur @click="onReset()" class="ml-3" variant="outline-secondary">Cancel</b-button>
          </b-col>
        </b-form-row>
      </b-form>
    </section>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { cloneDeep } from 'lodash'
import { DISPLAY_MESSAGES } from '@/utilities/constants'
import { useAxios } from '@/composables/useAxios'
import { ServiceUrls } from '@/utilities/serviceUrls'
import { useValidateFields } from '@/composables/useValidateFields'
import LoaderIcon from '@/assets/svg/loader.svg'
import InfoCircelYellow from '@/assets/svg/info-circle-yellow.svg'
import { tenantSpecificURL } from '@/utilities/utilities'

export default {
  name: 'AddEditLanguage',
  setup() {
    const { validateUrl, scrollToErrorMessage } = useValidateFields()
    const { isLoading: addEditLanguageLoading, response: addEditLanguageResponse, error: addEditLanguageError, callApi: addEditLanguageApi } = useAxios()
    const {
      isLoading: getLanguageDetailsLoading,
      response: getLanguageDetailsResponse,
      error: getLanguageDetailsError,
      callApi: getLanguageDetailsApi,
    } = useAxios()
    const { response: getLanguagesListResponse, callApi: getLanguagesListApi } = useAxios()
    const { response: fallbackLanguagesListResponse, callApi: fallbackLanguagesListApi } = useAxios()
    return {
      validateUrl,
      scrollToErrorMessage,
      addEditLanguageLoading,
      addEditLanguageResponse,
      addEditLanguageError,
      addEditLanguageApi,
      getLanguageDetailsLoading,
      getLanguageDetailsResponse,
      getLanguageDetailsError,
      getLanguageDetailsApi,
      getLanguagesListResponse,
      getLanguagesListApi,
      fallbackLanguagesListResponse,
      fallbackLanguagesListApi,
    }
  },
  components: {
    LoaderIcon,
    InfoCircelYellow,
  },
  data() {
    return {
      isEditPage: false,
      cloneLanguageForm: null,
      languageForm: {
        id: 0,
        tenantId: 0,
        languageName: null,
        cultureCode: null,
        isDefault: false,
        fallBackLanguageId: null,
      },
      formSubmitted: false,
      DISPLAY_MESSAGES: DISPLAY_MESSAGES,
      languagesList: [],
    }
  },
  computed: {
    showLoader() {
      return this.isEditPage ? this.getLanguageDetailsLoading : false
    },
    filterFallbackLanguages() {
      return this.fallbackLanguagesListResponse
        ? this.fallbackLanguagesListResponse.filter((language) => language.cultureCode !== this.languageForm.cultureCode)
        : []
    },
    showWarningDefaultMsg() {
      if (this.fallbackLanguagesListResponse) {
        const defaultLanguage = this.fallbackLanguagesListResponse.find((language) => language.isDefault)
        if (defaultLanguage && this.languageForm.isDefault) {
          return defaultLanguage.cultureCode === this.languageForm.cultureCode
            ? false
            : `Default language is going to change from ${defaultLanguage.languageName} to ${this.languageForm.languageName}`
        }
      }
      return false
    },
    ...mapState({
      selectedTenant: (state) => state.common.selectedTenant,
    }),
  },
  created() {
    this.getLanguagesList()
    this.getFallbackLanguagesList()
    if (this.$route.name === 'Edit-language') {
      this.isEditPage = true
      this.getLanguageDetails()
    }
  },
  mounted() {
    this.cloneLanguageForm = cloneDeep(this.languageForm)
  },
  methods: {
    showToaster(message, key, type) {
      this.$store.commit('common/setCustomToastData', {
        message: message,
        key: key,
        type: type,
      })
    },
    async getLanguagesList() {
      await this.getLanguagesListApi({ method: 'get', url: ServiceUrls.getLanguagesList })
      if (this.getLanguagesListResponse) {
        this.languagesList = this.getLanguagesListResponse
      }
    },
    async getFallbackLanguagesList() {
      await this.fallbackLanguagesListApi({ method: 'get', url: tenantSpecificURL(ServiceUrls.getLanguages) })
    },
    onChangeLanguage() {
      const language = this.languagesList.find((language) => language.countryName === this.languageForm.languageName)
      this.languageForm.cultureCode = language?.cultureCode || null
      const fallBackLanguage = this.fallbackLanguagesListResponse.find((language) => language.id === this.languageForm.fallBackLanguageId)
      if (fallBackLanguage && this.languageForm.cultureCode === fallBackLanguage.cultureCode) {
        this.languageForm.fallBackLanguageId = null
      }
    },
    async getLanguageDetails() {
      if (this.isEditPage) {
        await this.getLanguageDetailsApi({ method: 'get', url: `${ServiceUrls.getLanguageDetails}/${this.$route.params.languageId}` })
        if (this.getLanguageDetailsResponse) {
          this.languageForm = this.getLanguageDetailsResponse
          this.cloneLanguageForm = cloneDeep(this.languageForm)
        }
        if (this.getLanguageDetailsError) {
          this.showToaster(false, 'LANGUAGE_DETAILS_ERROR', 'danger')
        }
      }
    },
    onReset() {
      this.languageForm = cloneDeep(this.cloneLanguageForm)
      this.formSubmitted = false
    },
    validateLanguageForm() {
      const languageName = !!this.languageForm.languageName
      const cultureCode = !!this.languageForm.cultureCode
      return languageName && cultureCode
    },
    async onSubmit() {
      this.formSubmitted = true
      this.scrollToErrorMessage()
      if (this.validateLanguageForm()) {
        this.languageForm.fallBackLanguageId = this.languageForm.fallBackLanguageId || null
        const url = this.isEditPage ? ServiceUrls.updateLanguage : ServiceUrls.addLanguage
        if (!this.isEditPage) {
          this.languageForm.tenantId = this.selectedTenant[0].tenantID
        }
        await this.addEditLanguageApi({ method: 'post', url: url, data: this.languageForm })
        if (this.addEditLanguageResponse) {
          let message = this.isEditPage ? DISPLAY_MESSAGES.UPDATE_LANGUAGE_SUCCESS : DISPLAY_MESSAGES.ADD_LANGUAGE_SUCCESS
          message += this.addEditLanguageResponse?.successMessage || ''
          this.showToaster(message, false, 'success')
          this.$router.push('/languages')
        }
        if (this.addEditLanguageError) {
          if (this.addEditLanguageError?.errorMessage) {
            this.showToaster(this.addEditLanguageError?.errorMessage, false, 'danger')
          } else {
            this.showToaster(false, 'FAILED', 'danger')
          }
        }
      }
    },
  },
  watch: {
    selectedTenant() {
      this.getLanguageDetails()
    },
  },
}
</script>
